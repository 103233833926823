.topFixedBanner{
    background-image: url("../image/banner.jpg");
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.topBannerOverlay{
    position: absolute;
    min-height: 500px;
    width: 100%;
    background: rgba(0,0,0,0.7);
}
.topContent{
    margin-top: 10rem;
}

.topContentCourse{
    margin-top: 6rem;
}

.courseFullTitle{
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 28px;
    font-weight: 600;
}

.courseSubTitle{
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.courseDes{
    color: #000000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
}



.topTitle{
    font-family: 'Montserreat',sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 48px;
}

.topSubTitle{
    font-family: 'Montserreat',sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 24px;
}


.serviceMainTitle{
    color: #004085;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: 'Montserreat', sans-serif;
    font-size: 28px;
    font-weight: 600;
}

.serviceName{
    margin-top: 1rem;
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.serviceDescription{
    color: #000000;
    margin-top: 1rem;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
}


.serviceCard{
    margin: 5px 5px 5px 5px;
    height: 22rem;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 4px 0 rgba(0,115,230, 0.2);
}

.serviceCard:hover{
    margin: 5px 5px 5px 5px;
    height: 22rem;
    padding: 60px 30px 60px 30px;
    box-shadow: 0 0 8px 0 rgba(0,115,230, 0.4);
}

.navTitle{
    text-decoration: none;
    font-family: 'Montserreat', sans-serif;
    color: #ffffff ;
    font-weight: 700;
    font-size: 24px;
}

.navTitle:hover{
    text-decoration: none;
}

.navTitleScroll{
    text-decoration: none;
    font-family: 'Montserreat', sans-serif;
    color: #65BDD9 !important;
    font-weight: 700;
    font-size: 24px;
}

.navTitleScroll:hover{
    text-decoration: none;
}

.navItem{
    font-size: 16px;
    text-decoration: none !important;
    font-family: 'Montserreat', 'sans-serif';
    font-weight: 400;
    color: #ffffff ;
}

.navItem:hover{
    color: #ff642b ;
}

.des{
    font-weight: 400;
    color: #000000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
}

.summarySection{
    margin-top: 5rem;
}


.summaryBanner{
    background-image: url("../image/banner.jpg");
    min-height: 350px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.summaryBannerOverlay{
    position: absolute;
    min-height: 350px;
    width: 100%;
    background: rgba(0,0,0,0.7);
}

.countNumber{
    color: #ffffff;
    font-family: 'Montserreat', sans-serif;
    font-size: 48px;
    font-weight: 600;
}

.countTitle{
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.countSection{
    margin-top: 16%;
}

.cardTitle{
    color: #000000;
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.cardSubTitle{
    color: #000000;
    font-family: 'Montserreat', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.iconBullet{
    color: #004085;
    font-size: 16px;
}

.workCard{
    margin-top: 14%;
    padding:  10px 10px 10px 10px;
    border-right: 2px;
}

.projectCard{
    border-radius: 2px;
    border: none !important;
    margin: 2px 2px 2px 2px;
    box-shadow: 0 0 4px 0 rgba(0,115,230, 0.2);
}

.projectCard:hover{
    box-shadow: 0 0 8px 0 rgba(0,115,230, 0.2);
}

.projectCardTitle{
    font-family: 'Montserreat', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.projectCardDes{
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.courseImg{
    width: 100%;
}

.courseTitle{
    font-family: 'Montserreat', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.courseDes{
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-weight: 400;
}

.courseDetails{
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
}

.courseDetails:hover{
    text-decoration: none;
}

.videoCard{
    background-color: #ffffff;
    margin: 50px auto;
    padding: 6rem 3rem 6rem 3rem;
    box-shadow: 0 0 4px 0 rgba(0,115,230, 0.2);
}

.playBtn{
    color: #0073e6;
    font-size: 48px;
    cursor: pointer;
}

.playBtn:hover{
    color: #fd7e14;
    font-size: 48px;
    cursor: pointer;
}

.videoTitle{
    color: #0073e6;
    font-family: 'Montserreat', sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.videoDes{
    /*color: #0073e6;*/
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.circleImg{
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid #007bff;
    border-radius: 50%;
}

/*.footerSection{*/
/*    margin-top: 5rem;*/
/*    box-shadow: 0 0 4px 0 rgba(0,115,230, 0.2);*/
/*}*/


.footerSection {
     color: #ffffff;
    margin-top: 5rem;
    background-color: #1dc1f4;
    background-image: linear-gradient(-90deg, rgb(56, 90, 228), rgb(8, 177, 62));
    box-shadow: 0 0 3px 0 rgba(4, 69, 181, 0.3);
}

.socialLink{
    text-decoration: none;
    color: #007bff;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.socialLink{
    text-decoration: none;
    color: #007bff;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.socialLink:hover{
    text-decoration: none;
    color: #000000;
}

.footerLink{
    color: #ffffff;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.footerLink:hover{
    color: #bdbebe;
    text-decoration: none;

}

.copyRightSection{
    padding: 20px 20px 20px 20px;
    background-color: #333940;
}

.copyRightLink{
    color: #ffffff;
    text-decoration: none;
    font-family: 'Montserreat', 'sans-serif';
    font-weight: 400;
    font-size: 20px;
}

/*.copyRightLink:hover{*/
/*    color: #007bff;*/
/*    text-decoration: none;*/
/*}*/


.topFixedPage{
    background-image: url("../image/banner.jpg");
    min-height: 250px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topPageOverlay{
    position: absolute;
    min-height: 250px;
    width: 100%;
    background: rgba(0,0,0,0.7);
}

.topPageContent{
    margin-top: 9rem;
}

.pageTitle{
    font-family: 'Raleway',sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 28px;
}


.form-control:focus{
    border-radius: 2px !important;
    border-color: #007bff  !important;
    box-shadow: 0 0 4px 0 rgba(0,115,230, 0.2)  !important;
}

.linkStyle{
    color: #ffffff !important;
}

.linkStyle:hover{
    text-decoration: none;
    color: #fd7e14 !important;
}

.csrSubTitle {
    color: #004085;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-family: 'Montserreat', sans-serif;
    font-size: 28px;
    font-weight: 600;
}


.teamTitle{
    text-decoration: none;
    font-family: 'Montserreat', sans-serif;
    color: #878b8f;
    font-weight: 700;
    font-size: 2.75rem;
}

.teamCard{
    color: #878b8f;
    /*text-decoration: none;*/
}
